﻿.av-videoboard-editor {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
}

.av-toolbar-input-group {
    @extend .input-group;
    @extend .input-group-sm;
    @extend .col-xl-4;
    @extend .col-lg-4;
    @extend .col-md-4;
    @extend .col-sm-4;
    @extend .col-4;

    .input-group-prepend {
        @media screen and (max-width: var(--breakpoint-xl)) {
            display: none;
        }
    }
}

.av-toolbar-btn-group {
    @extend .btn-group;
}

.av-videoboard-name {
    max-height: 3rem;
    @extend .d-flex;
    @extend .align-items-end;
    @extend .mb-2;

    .name-input {
        @extend .col-8;
        @extend .col-sm-8;
        @extend .col-md-7;
        @extend .col-lg-5;
        @extend .col-xl-5;
        @extend .pl-0 !optional;
        @extend .pr-2 !optional;

        .av-videoboard-name-control {
            width: 100%;
            font-size: 1.5rem;
            border-width: 0 0 .25rem 0;
            border-color: rgba(30, 130, 76, .25);

            &:hover {
                border-color: rgba(30, 130, 76, .5);
            }

            &:focus {
                outline-width: 0;
                border-color: rgb(30, 130, 76);
            }

            @extend .bg-transparent;
        }
    }

    .spacer {
        @extend .col-2;
        @extend .col-sm-3;
        @extend .col-md-4;
        @extend .col-lg-5;
        @extend .col-xl-5;
    }

    .save-btn {
        @extend .text-right !optional;
        @extend .col-2;
        @extend .col-sm-1;
        @extend .col-md-1;
        @extend .col-lg-2;
        @extend .col-xl-2;
        @extend .p-0;

        .save-label {
            @media screen and (max-width: var(--breakpoint-xl)) {
                display: none;
            }
        }
    }
}

