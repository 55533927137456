﻿.my-input-highlight{

    border: thin solid #1e824c;

    &:focus{
        outline: none;
        border: 1px solid #1e824c;
        box-shadow: 0px 0px 10px 0px rgba(74,213,138,1);
        -webkit-box-shadow: 0px 0px 10px 0px rgba(74,213,138,1);
        -moz-box-shadow: 0px 0px 10px 0px rgba(74,213,138,1);
    }
}

.form-label {
    &.loading {
        &:after {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: "\f1ce";
            display: inline-block;
            animation: spinner 2s infinite linear;

            @extend .text-muted;
            @extend .ml-1 !optional;
        }
    }
}

.av-progress-bar {
    width: 100%;

    @extend .progress-bar;
    @extend .progress-bar-striped;
    @extend .progress-bar-animated;
}

.page-loader-container,
.message-container {
    height: 100%;
    display: grid;
    grid-template-rows: fr1;
    grid-template-columns: fr1;
}

.page-loader {
    font-size: 5rem;
    justify-self: center;
    align-self: center;

    @extend .text-muted;
}

.av-dropdown {
    text-transform: uppercase;
    @extend .form-control;
    @extend .form-control-sm;
}

.av-txt-input {
    @extend .form-control;
    @extend .form-control-sm;
}

.av-btn {
    @extend .btn;
    @extend .btn-primary;

    &.block {
        @extend .btn-block !optional;
    }
}

.av-outline-btn {
    @extend .btn;
    @extend .btn-outline-primary;

    &.block {
        @extend .btn-block !optional;
    }
}

.av-center-alert-container {
    justify-self: center;
    align-self: center;
}

.av-welcome-alert,
.av-primary-alert,
.av-warning-alert,
.av-danger-alert {
    font-weight: bold;
    font-size: 1.5rem;

    @extend .alert;
    @extend .pl-5 !optional;
    @extend .pr-5 !optional;
    @extend .text-center;

    &.av-nav-alert {
        font-size: 1rem;

        @extend .pl-3 !optional;
        @extend .pr-3 !optional;
        @extend .text-justify !optional;
    }
}

.av-welcome-alert {
    color: #fff;
    background-color: #1e824c;
}

.av-primary-alert {
    @extend .alert-primary;
}

.av-warning-alert {
    @extend .alert-warning;
}

.av-danger-alert {
    @extend .alert-danger;
}

.av-unavailable-alert {
    height: 100%;

    @extend .alert;
    @extend .alert-light;
    @extend .bg-light;
    @extend .text-center;
    @extend .d-flex;
    @extend .flex-column;
    @extend .justify-content-center;
    @extend .mb-1;
}

.av-camera-preview-img,
.av-camera-unavailable-img,
.av-no-camera-img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}