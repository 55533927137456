﻿$algo-green: #1E824C;

.pill-span {
    background-color: mix(#fff, $algo-green, 75%);
}

.pill-camera-preview {
    width: auto;
    height: 24px;
    padding: 3px 8px;
    margin: 8px 8px 0 0;
    border-radius: 4px;
    line-height: 18px;
    font-size: .85rem;
    //font-weight: bold;
    color: #fff;
    background-color: #C4C4C4;
}

.pill-status-on {

    @extend .pill-camera-preview;
    background-color: #01B420;
    //background-color: mix(white, map-get($site-colors, "green"), 75%);
    //color: map-get($site-colors, "green");
}

.pill-status-off {

    @extend .pill-camera-preview;
    background-color: #E53424;
    //background-color: mix(white, map-get($site-colors, "red"), 75%);
    //color: map-get($site-colors, "red");
}

.pill-secondary {

    @extend .pill-camera-preview;
    color: #000;
    //background-color: mix(white, #696969, 75%);
    //color: #696969;
}

.pill-public {

    @extend .pill-camera-preview;

    //background-color: mix(white, map-get($access-level-colors, "Full Access"), 70%);
    //color: map-get($access-level-colors, "Full Access");
    
}

.pill-first-responders {

    @extend .pill-camera-preview;
    //background-color: mix(white, map-get($access-level-colors, "First Responders"), 70%);
    //color: map-get($access-level-colors, "First Responders");
}

.pill-media {

    @extend .pill-camera-preview;
    //background-color: mix(white, map-get($access-level-colors, "Media"), 70%);
    //color: map-get($access-level-colors, "Media");
}

.pill-private-sector {

    @extend .pill-camera-preview;

    //background-color: mix(white, map-get($access-level-colors, "Private Sector"), 70%);
    //color: map-get($access-level-colors, "Private Sector");
}

.pill-tmc-operator {

    @extend .pill-camera-preview;

    //background-color: mix(white, map-get($access-level-colors, "TMC Operator"), 70%);
    //color: map-get($access-level-colors, "TMC Operator");
}

.pill-unknown {

    @extend .pill-camera-preview;
    //background-color: mix(white, #696969, 75%);
    //color: #696969;
}