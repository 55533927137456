@import url('https://fonts.googleapis.com/css?family=Roboto');

$theme-colors: ( "primary": #1e824c, "secondary": rgba(#EFEFF0, 0.5), "success": #01B420, "warning": #F7CA18, "danger": #E53424, "info": #22A7F0, "dark": #696969, "light": #EFEFF0 );

$input-btn-focus-width: .08rem;
$input-btn-focus-color: map-get($theme-colors, "primary");

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


/* COLORS */
@import "colors.scss";


/* BOOTSTRAP */
    
$spacers: ( 0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3), 6: ($spacer * 5), 7: ($spacer * 10), 8: ($spacer * 20) );

$grid-breakpoints: ( 
    xxxs: 0, 
    xxs: 320px, 
    xs: 568px, 
    sm: 667px, 
    md: 768px, 
    lg: 992px, 
    xl: 1200px,
    algoxl: 1340px,
    xxl: 1440px, 
    xxxl: 1600px 
);

$container-max-widths: ( 
    xxxs: 0, 
    xxs: 320px, 
    xs: 568px, 
    sm: 667px, 
    md: 768px, 
    lg: 992px, 
    xl: 1200px,
    algoxl: 1340px,
    xxl: 1440px, 
    xxxl: 1600px 
);

//$enable-rounded: true;
//$border-radius: 18px;
$enable-shadows: true;
$enable-gradients: true;
$font-family-base: --apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;

@import "~bootstrap/scss/mixins/_breakpoints";
@import "~bootstrap/scss/bootstrap";

/* FONT AWESOME */
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";

/* VIDEOJS */
@import "~video.js/dist/video-js.css";

/* REACT DATETIME */
@import "reactDatetime.scss";

/* ALGO ADMIN */
@import "core.scss";

@import "animation.scss";

@import "baseLayout.scss";

@import "baseElements.scss";

@import "editorComponent.scss";

@import "adminDashboardComponent.scss";

@import "adminEditorComponent.scss";

@import "serviceStatusComponent.scss";

@import "videojs-skin.scss";

/* EFFECTS */
@import "effects.scss";

/* SCROLLBAR */
@import "scrollbar.scss";

/* TEXT FORMAT */
@import "textFormat.scss";

/* PILLS */
@import "pills.scss";

/* PAGES */
@import "cameraDashboard.scss";
@import "streamLogNav.scss";
