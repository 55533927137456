﻿$route-transition-duration: 250ms;

@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(359deg);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fade-enter {
    opacity: 0;
    z-index: 1;

    &.fade-enter-active {
        opacity: 1;
        transition: opacity $route-transition-duration ease-in;
    }
}

.fade-exit {
    opacity: 1;
    z-index: 0;
    display: none;

    &.fade-exit-active {
        opacity: 0;
        transition: opacity $route-transition-duration ease-in;
    }
}

.editor-enter {
    display: none;
    opacity: 0.01;
}

.editor-enter.editor-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
}

.editor-leave {
    opacity: 1;
}

.editor-leave.editor-leave-active {
    display: none;
    /*opacity: 0.01;
    transition: opacity 100ms ease-in;*/
}
.editor-appear {
    opacity: 0.01;
}

.editor-appear.editor-appear-active {
    opacity: 1;
    transition: opacity .5s ease-in;
}

.slide-enter {
    transform: translate(100%);
}

.slide-enter.slide-enter-active {
    transform: translate(0%);
    transition: transform 500ms ease-in-out;
}

.slide-leave {
    transform: translate(0%);
}

.slide-leave.slide-leave-active {
    transform: translate(-100%);
    transition: transform 500ms ease-in-out;
}