﻿.av-center-grid {
    height: 100%;
    display: grid;
    grid-row-gap: .5rem;
    grid-column-gap: .5rem;
}

.av-modal-table {
    @extend .table;
    @extend .table-hover;
    @extend .table-striped;
    margin-bottom: 0;

    tbody {
        tr {
            td:first-child {
                font-weight: bold;
            }
        }
    }
}

.add-icon {
    &:before {
        display: inline-block;
        width: 1rem;
        padding-right: .5rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f067";
    }

    &.saving {
        &:before {
            content: "\f1ce";
            animation: spinner 2s infinite linear;
        }
    }
}

.save-icon {
    &:before {
        display: inline-block;
        width: 1rem;
        padding-right: .5rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f0c7";
    }

    &.saving {
        &:before {
            content: "\f1ce";
            animation: spinner 2s infinite linear;
        }
    }
}

.delete-icon {
    &:before {
        display: inline-block;
        width: 1rem;
        padding-right: .5rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f2ed";
    }

    &.deleting {
        &:before {
            content: "\f1ce";
            animation: spinner 2s infinite linear;
        }
    }
}

.send-icon {
    &:before {
        display: inline-block;
        width: 1rem;
        padding-right: .5rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f1d8";
    }

    &.sending {
        &:before {
            content: "\f1ce";
            animation: spinner 2s infinite linear;
        }
    }
}

.av-auth-btn {
    .av-auth-btn-label {
        &:before {
            padding-right: .5rem;
            width: 1rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            display: inline-block;
        }
    }

    &.av-auth-signin-btn {
        @extend .btn;
        @extend .btn-outline-success;

        .av-auth-btn-label {
            &:before {
                content: "\f2f6";
            }
        }

        &.loading {
            .av-auth-btn-label {
                &:before {
                    content: "\f1ce";
                    animation: spinner 2s infinite linear;
                }
            }
        }
    }
}

.av-auth-signout-btn {
    @extend .btn;
    @extend .btn-outline-primary;

    .av-auth-btn-label {
        &:before {
            content: "\f007";
        }
    }
}

.av-navbar {
    min-height: var(--navbar-height);
    border-bottom: var(--border-width) solid var(--border-color);

    @extend .px-2;

    @media screen and (min-width: var(--breakpoint-sm)) {
        position: sticky;
        top: 0;
        z-index: 1010;
    }

    .av-nav-section {
        @extend .navbar-nav;
        @extend .mr-auto !optional;
        @extend .mb-0;
    }

    .nav-item {
        &.loading {
            font-size: .8rem;

            @extend .text-center;
            @extend .text-muted;
        }
    }

    .av-logo {
        width: 36px;
        height: 36px;
    }

    @extend .navbar;
    @extend .navbar-expand-xl;
    @extend .navbar-light;
}

.av-nav-link {
    font-weight: bold;
    @extend .nav-link;

    &.active {
        @extend .text-primary;
    }

    &.disabled {
        cursor: default;
    }
}

.av-flex-container {
    @extend .d-flex;
    @extend .flex-row;
    @extend .flex-xl-nowrap;
}

.av-sidebar {
    @media screen and (min-width: var(--breakpoint-sm)) {
        position: sticky;
        top: var(--navbar-height);
        z-index: 1000;
        order: 0;
        height: calc(100vh - var(--navbar-height));
        border-right: 1px solid rgba(0,0,0,.1);
        padding: 0;
    }

    @extend .bg-white;
    @extend .col-12;
    @extend .col-sm-12;
    @extend .col-md-3;
    @extend .col-xl-2;
    @extend .p-0;

    .av-section {
        border-bottom: var(--border-width) solid var(--border-color);
        padding: 1rem .4rem 1rem .4rem;
        height: var(--section-height);

        @extend .bg-secondary;

        h2, h3 {
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
        }

        h2 {
            margin: 0;
            font-size: 1.1rem;
        }

        h3 {
            font-size: 1rem;
        }

        .form-label {
            font-weight: bold;
            text-transform: uppercase;
        }

        &.av-section-header {
            height: var(--sidebar-header-height);
        }

        &.av-section-header,
        &.av-section-nav,
        &.av-section-legend {
            @extend .bg-white;
        }

        &.av-section-nav,
        &.av-section-save,
        &.av-section-legend,
        &.av-section-bulk-save {
            height: unset;
        }

        &.av-section-legend {
            ul {
                list-style-type: circle;
                font-size: .8em;
                @extend .pl-3 !optional;
                @extend .pr-3 !optional;
                @extend .pt-0;
                @extend .pb-0;
                @extend .mb-0;

                li {
                    @extend .mb-3;
                    line-height: 1.2rem;
                }

                :last-child {
                    @extend .mb-0;
                }
            }
        }

        .av-section-navbar {
            @extend .nav;
            @extend .flex-column;
            @extend .justify-content-center;

            .av-nav-link {
                font-size: .8rem;
            }
        }
    }
}

.av-content {
    order: 1;

    &.primary {
        @extend .col-12;
        @extend .p-0;
        @extend .m-0;
    }

    &.shared {
        @extend .col-12;
        @extend .col-sm-12;
        @extend .col-md-9;
        @extend .col-xl-10;
        @extend .m-0;
        @extend .p-0;
    }
}

.av-editor-tabs {
    height: var(--editor-tabs-height);
    @extend .btn-group;
    @extend .btn-group-sm;
    @extend .btn-group-toggle !optional;
    @extend .d-flex;
    @extend .mt-2;
    @extend .mx-2;
}

.av-dashboard-content,
.av-admin-dashboard-content,
.av-admin-editor-content,
.av-editor-content,
.av-loader-content,
.av-message-content {
    @extend .p-2;

    &.fluid {
        @extend .px-0;
        @extend .py-0;
    }
}

.av-dashboard-content,
.av-loader-content,
.av-message-content,
.av-welcome-content {
    height: calc(100vh - var(--navbar-height));

    @media screen and (max-width: var(--breakpoint-sm)) {
        height: calc(100vh - var(--navbar-height));
    }
}

.av-admin-dashboard-content,
.av-admin-editor-content {
    height: calc(100vh - var(--navbar-height));
    overflow-y: scroll;

    @media screen and (max-width: var(--breakpoint-sm)) {
        height: calc(100vh - var(--navbar-height));
    }

    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
    }

    .av-admin-editor-img-container {
        @extend .d-flex;
        @extend .justify-content-center;

        img {
            //@extend .img-thumbnail;
            //@extend .mb-3;
            height: 14rem;
            object-fit: cover;
        }
    }
}

.av-welcome-content {
    height: calc(100vh - var(--navbar-height));
}

.av-guide-content {
    height: calc(100vh - var(--navbar-height) - var(--spacer-gap));
}

.av-editor-content {
    height: calc(100vh - var(--navbar-height) - var(--editor-tabs-height) - var(--spacer-gap));

    @media screen and (max-width: var(--breakpoint-sm)) {
        height: calc(100vh - var(--navbar-height) - var(--editor-tabs-height) - var(--spacer-gap));
    }
}

.av-site-guide {
    width: 100%;
    height: 100%;
    padding:0;
    margin:0;
}

.av-welcome-content {
    background: url('../images/background@1x.jpg') no-repeat center center fixed;
    background-size: cover;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        background-image: url('../images/background@2x.jpg');
    }

    .av-welcome-container {
        height: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;

        .av-welcome {
            align-self: center;
            display: grid;
            grid-template-columns: 1fr;
            color: white;

            .av-welcome-text {
                align-self: center;

                h1,
                h2 {
                    text-shadow: 0.075rem 0.075rem 0 rgba(0,0,0,1);
                    font-weight: 900;
                    text-align: center;
                }

                h1 {
                    @extend .mb-3;

                    img {
                        width: 10rem;
                        height: 10rem;
                    }
                }
            }
        }
    }
}

.av-fatal-error-container {
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100vh;

    .av-error-content {
        align-self: center;
        justify-self: middle;
        
        @extend .text-center;
    }
}