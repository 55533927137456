﻿//custom styles for StreeamLogNav Component bootstrap nav/tab/pills

.nav-pills {
    .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 52px;
        background-color: #c4c4c4;
        color: #000;

        &.active {
            color: #fff;
            background-color: #00805D;
        }
    }
}
