﻿$border-radius: "4px";

html{
    height: 100%;
}

body {
    height: 100%;
}

#root {
    height: 100%;

    > div:first-child{
        height: 100%;
    }
}

.camera-dashboard-primary {
    .toolbar-container {
        .toolbar-card {
            @extend .card;
            padding: .5rem;
            border-radius: $border-radius;

            .toolbar-inner-container {
                @extend .container-fluid;

                .toolbar-basic-row {
                    @extend .row;

                    .toolbar-menu-col {
                        @extend .col-lg-2;
                        @extend .col-sm-2;
                        @extend .col-3;
                        @extend .mb-sm-0;
                        @extend .mb-2;
                        display: flex;
                        align-items: center;
                        padding-left: 0;

                        .toolbar-menu-btn {
                            @extend .btn;
                            @extend .btn-outline-primary;
                            width: 100%;
                            border-radius: $border-radius;
                        }
                    }

                    .toolbar-datasource-col {
                        @extend .col-xl-4;
                        @extend .col-lg-4;
                        @extend .col-sm-4;
                        @extend .col-9;
                        @extend .pr-sm-3 !optional;
                        @extend .pr-0 !optional;
                        @extend .mb-sm-0;
                        @extend .mb-2;
                        width: 100%;
                        display: flex;
                        align-items: center;

                        .form-control {
                            border-radius: $border-radius;
                        }
                    }

                    .toolbar-search-col-bar {
                        @extend .col-xl-4;
                        @extend .col-lg-4;
                        @extend .col-sm-4;
                        @extend .col-9;
                        @extend .pl-sm-3 !optional;
                        @extend .pl-0 !optional;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        .form-control-rounded {
                            @extend .form-control;
                            width: 100%;
                            display: inline;
                            border-top-left-radius: $border-radius;
                            border-bottom-left-radius: $border-radius;
                        }
                    }

                    .toolbar-search-col-btn {
                        @extend .col-lg-2;
                        @extend .col-sm-2;
                        @extend .col-3;
                        align-items: center;
                        padding-right: 0;

                        .toolbar-search-btn {
                            width: 100%;
                            @extend .btn;
                            @extend .btn-outline-primary;
                            @extend .px-xl-5;
                            @extend .px-lg-3;
                            border-top-right-radius: $border-radius;
                            border-bottom-right-radius: $border-radius;
                        }
                    }
                }
            }
        }
    }

    .camera-preview-container {
        .camera-preview-row {
            .camera-newstream-col {
                
                margin: 0 0 1.5rem 0;

                .newstream-button {
                    @extend .container-fluid;
                    width: 100%;
                    border-radius: $border-radius;
                    background-color: mix(white, map-get($theme-colors, "primary"), 75%);
                    color: map-get($theme-colors, "primary");
                }
            }

            .camera-preview-col {
                @extend .col-algoxl-3;
                @extend .col-lg-4;
                @extend .col-md-6;
                @extend .col-sm-12;
                margin: 0 0 1.5rem 0;
            }
        }
    }

    .to-top-primary {
        position: absolute;
        top: 90%;
        left: 0;
        width: 100%;

        .to-top-button {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .to-top-pill {
            width: 165px;
            padding: 4px 4px;
            border-radius: 20px;
            color: map-get($theme-colors, "primary");
            font-weight: bold;
            box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.45);
            cursor: pointer;
        }

        .to-top-pill-icon {
            margin: 0 1rem 0 3rem;
        }
    }
}

.to-top-pill {
    width: 165px;
    padding: 4px 0 4px 4px;
    border-radius: 20px;
    color: map-get($theme-colors, "primary");
    font-weight: bold;
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.45);
    cursor: pointer;
}
