﻿.av-snapshot-preview-container {
    /*Styles for the CameraPreview Component of the CameraEditor Page*/
    @extend .container-fluid;
    @extend .d-flex;
    @extend .justify-content-center;
    height: auto;

    .av-snapshot-preview {
        @extend .card;
        width: 16rem;
        height: 17rem;

        &.button {
            height: 17rem;
        }

        &.no-button {
            height: 16rem;
        }

        .av-camera-img {
            background-color: var(--light);

            @extend .card-img-top;

            &.contain {
                object-fit: contain;
            }

            &.cover {
                object-fit: cover;
            }

            &.fill {
                object-fit: fill;
            }

            &.scale-down {
                object-fit: scale-down;
            }

            &.no-button {
                height: 10rem;
            }

            &.button {
                height: 8rem;
            }
        }

        .av-snapshot-preview-streets {
            @extend .card-body;
            height: 4rem;
            //overflow: hidden;
            //text-overflow: ellipsis;
            //white-space: nowrap;
            @extend .text-center;

            .av-snapshot-preview-streets-primary {
                @extend .text-primary;
                font-weight: bold;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .av-snapshot-preview-streets-cross {
                @extend .text-muted;
                padding: 10px 10px 10px 10px;
                font-size: .8rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
}

    .av-organization-editor,
    .av-user-editor,
    .av-feedback-detail,
    .av-alert-editor {
        display: grid;

        @include media-breakpoint-up(xs) {
            grid-template-columns: 1fr 90% 1fr;
        }

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1fr 90% 1fr;
        }

        @include media-breakpoint-up(md) {
            grid-template-columns: 1fr 80% 1fr;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 60% 1fr;
        }

        grid-template-rows: auto;
    }

    .av-form-textarea {
        width: 100%;
        height: 20vh;
        @extend .form-control;
    }

    .av-admin-toolbar {
        &.av-user-editor-toolbar {
            grid-row: 1;
            grid-column: 2;
        }


        &.av-user-authorization-toolbar {
            grid-row: 2;
            grid-column: 2;
        }

        &.av-user-editor-toolbar,
        &.av-user-authorization-toolbar {
            grid-template-columns: 25% 1fr 25%;

            .av-reset-password,
            .av-organization-selector {
                grid-row: 1;
                grid-column: 2;
            }

            .av-save-user,
            .av-role-selector {
                grid-row: 1;
                grid-column: 3;
            }
        }

        .av-admin-search-group {

            select {
                text-transform: capitalize;
            }
        }
    }

    .av-admin-form {

        .av-admin-form-header {
            @extend .mt-3;
        }
    }

    .av-admin-form,
    .av-feedback-detail-section {
        grid-column: 2;

        @extend .rounded;
        @extend .bg-white;
        @extend .shadow-sm;
        @extend .py-1;
        @extend .px-4;
        @extend .mb-2;

        .av-feedback-section-header {
            @extend .text-dark;
        }

        &.titlebar {
            .av-back-action {
                justify-content: center;
                align-items: center;

                @extend .d-flex;
                @extend .flex-row;
                @extend .no-gutters !optional;
                @extend .text-primary;
            }

            .av-toolbar-title {
                justify-content: center;
                align-items: center;
                /*@media screen and (max-width: var(--breakpoint-sm)) {
                visibility: hidden;
            }*/
                h1 {
                    text-align: center;
                    font-weight: bold;
                    font-size: 1.5em;

                    @extend .mb-1;
                }

                .av-feedback-contact {
                    font-weight: bold;
                }

                .av-feedback-contact,
                .av-feedback-created {
                    font-size: .9em;

                    @extend .mb-1;
                    @extend .text-dark;
                }

                @extend .col-8;
                @extend .flex-column;
                @extend .text-center;
            }
        }
    }

.av-password-requirement-alert {
    @extend .alert;
    @extend .alert-primary;

    .av-admin-editor-alert-header {
        font-size: 1.1rem;
        font-weight: bold;
    }

    hr {
        @extend .m-1;
    }

    ul {
        @extend .mb-0;

        li {
            list-style-type: square;

            &.is-valid {
                list-style-type: none;

                &:before {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    content: "\f00c";
                    display: inline-block;
                    margin-left: -1.3em;
                    width: 1.3em;
                    font-size: .9rem;
                }
            }

            &.is-invalid {
                color: var(--danger);
                list-style-type: none;

                &:before {
                    font-family: 'Font Awesome 5 Free';
                    font-weight: 900;
                    content: "\f00d";
                    display: inline-block;
                    margin-left: -1.3em;
                    width: 1.3em;
                    font-size: .9rem;
                }
            }
        }
    }
}

    .av-reset-password-alert {
        @extend .alert;
        @extend .alert-warning;

        p {
            &:last-child {
                @extend .mb-0;
            }
        }
    }


    .av-admin-editor-form-row {
        @extend .form-row !optional;
        @extend .mb-2;

        &:last-child {
            @extend .mb-0;
        }
    }

    .av-admin-editor-form-label {
        font-size: .8rem;
        font-weight: bold;

        @extend .text-right !optional;
        @extend .col-3;
        @extend .col-form-label;
    }

    .av-admin-editor-form-item {
        @extend .col-9;

        &.fluid {
            @extend .col-12;
        }

        .av-selector__single-value {
            text-transform: capitalize;
        }

        .av-selector__option {
            text-transform: capitalize;
        }

        &.av-admin-editor-alert-item{
            @extend .col-12;

        }

        .av-admin-editor-legend,
        .av-admin-editor-alert{
            @extend .m-0;
            @extend .w-100;

            .av-admin-editor-alert-header{
                font-size: 1.1rem;
                font-weight: bold;
            }

            hr{
                @extend .m-1;
            }

            ul{
                list-style-type: none;
                @extend .m-0;
                @extend .p-0;

                li{
                    font-size: 0.9rem;
                }
            }
        }
    }

    .av-add-org-user {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 75% 1fr;
        grid-gap: .25rem;

        @extend .mb-2;
    }
