﻿.av-organization-dashboard,
.av-user-dashboard {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
}

.av-feedback-dashboard {
    height: 100%;
}

.av-admin-toolbar {
    display: grid;
    grid-gap: .5rem;
    grid-template-rows: 1fr;
    align-items: center;
    justify-content: center;

    @extend .rounded;
    @extend .bg-white;
    @extend .shadow-sm;
    @extend .p-1;
    @extend .m-2;

    &.av-search-toolbar {
        grid-template-columns: 1fr;
    }

    &.av-select-search-toolbar {
        grid-template-columns: 25% 75%;
    }

    &.av-bulk-update-toolbar {
        grid-template-columns: 35% 50% 15%;

        @include media-breakpoint-up(md) {
            grid-template-columns: 20% 60% 20%;
        }

        @extend .pr-2 !optional;
        @extend .pl-2 !optional;

        .av-admin-search-group {
            //@extend .mx-auto;
            .bulk-update-label {
                //@extend .mx-auto;
                @extend .w-100;
                font-weight: bold;
                border-color: rgba(0,0,0,.1);
                text-align: center;
                padding: 6px 3px;
            }

            .av-selector-container {
                @extend .w-100;
            }

            .update-all-button {
                @extend .w-100;
                @extend .d-flex;
                @extend .justify-content-center;

                .update-all-text {
                    display: none;

                    @include media-breakpoint-up(md) {
                        display: inline;
                    }
                }
            }
        }
    }
}

.av-admin-search-group {
    @extend .input-group;
    @extend .w-100;

    option{
        text-transform: capitalize;
    }
    .input-group-append{
        z-index: 0;
    }
}

.av-admin-page-size-group {
    @extend .input-group;
    @extend .input-group-sm;
    @extend .w-100;
}

.av-admin-search,
.av-admin-page-size {
    @extend .form-control;
}

.av-admin-search-append-text,
.av-admin-page-size-append-text {
    @extend .input-group-text;
}

.av-admin-search-clear {
    @extend .btn;
    @extend .btn-block !optional;
    @extend .btn-sm;
    @extend .btn-outline-danger;
}

.av-admin-search-apply {
    @extend .btn;
    @extend .btn-block !optional;
    @extend .btn-sm;
    @extend .btn-outline-primary;
}

.av-admin-table-page-size {
    @extend .form-control;
    /*@extend .form-control-sm;*/
}

.av-admin-add-btn {
    @extend .btn;
    /*@extend .btn-sm;*/
    @extend .btn-outline-primary;
}

.av-admin-table-actions {
    @extend .d-flex;
    @extend .flex-row;
    @extend .justify-content-center;

    div {
        @extend .p-1;
    }
}

.av-admin-table-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;

    &.searchable {
        grid-template-rows: auto 1fr auto;

        &.children {
            grid-template-rows: auto auto 1fr auto;
        }
    }

    &.children {
        grid-template-rows: auto 1fr auto;
    }
}

.av-admin-table-scroll-container {
    overflow-y: auto;
    
    -ms-overflow-style: none; // IE 10+
    scrollbar-width: none; // Firefox
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.av-pager {
    @extend .mt-2;
    @extend .mb-0;
    @extend .pb-1;
    @extend .pagination;
    @extend .justify-content-center;
    font-size: 0.8rem;
    @include media-breakpoint-up(md){
        font-size: 1rem;
    }

    .page-item {

        &.active {
            .page-link {
                @extend .bg-primary;
                @extend .border-primary;
                @extend .text-white;
            }
        }

        &.disabled {
            .page-link {
                @extend .text-muted;
            }
        }

        .page-link {
            @extend .text-primary;

            &:focus {
                box-shadow: 0 0 0 0.2rem rgba(#1e824c, 0.25);
            }
        }
    }
}

.av-admin-table {
    /*height: 100%;*/
    @extend .table;
    @extend .table-striped;
    @extend .table-hover;
    @extend .mb-0;

    .av-admin-table-row {
        &.clickable {
            cursor: pointer;
        }
    }

    thead th {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        /*z-index: 10;*/
        border: none;
    }

    tbody {
        td {
            @extend .align-middle;
        }
    }

    tfoot {
        td {
            @extend .p-0;
        }
    }

    .av-admin-empty-table {
        font-weight: bold;

        @extend .text-center;
        @extend .align-middle;
    }

    .av-admin-col {
        @extend .align-middle;

        &.av-admin-actions-col {
            width: 10%;

            @extend .text-center;
        }
    }

    &.av-organization-table {
        .av-organization-name-col {
            width: 45%;
        }

        .av-organization-description-col {
            width: 45%;
        }
    }

    &.av-user-table {
        .av-user-name-col,
        .av-user-username-col,
        .av-user-email-col {
            width: 25%;
        }
    }

    &.av-feedback-table {
        .av-feedback-name-col {
            width: 20%;
        }

        .av-feedback-subject-col {
            width: 50%;
        }

        .av-feedback-received-col {
            width: 20%;
        }

        .read {
            color: rgba(0, 0, 0, 0.4);
        }

        .unread {
            @extend .text-primary;
        }
    }

    &.av-camera-table {
        .av-camera-cameraName-col {
            width: 25%;
        }

        .av-camera-primaryRoad-col {
            width: 25%;
        }

        .av-camera-crossStreet-col {
            width: 25%;
        }
        .av-camera-access-level-col{
            width: 25%;
        }
    }

    &.av-alert-table {
        .av-alert-title-col {
            width: 45%;
        }

        .av-alert-start-col,
        .av-alert-end-col,
        .av-alert-created-col {
            width: 15%;
        }
    }
}

.av-facility-name-col {
    text-align: start;
    width: 20%;
}

.av-facility-direction-col {
    text-align: start;
    width: 20%;
}

.av-facility-code-col {
    text-align: start;
    width: 20%;
}

.av-facility-type-col {
    text-align: start;
    width: 20%;
}

.av-facility-open-col {
    text-align: center;
    width: 20%;
}

.av-feedback-bool-indicator {
    &.off {
        &::before {
            display: inline-block;
            width: 1rem;
            padding-right: .5rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: "\f658";
        }
    
        color: rgba(0,0,0,0.4);
    }

    &.on {
        &::before {
            display: inline-block;
            width: 1rem;
            padding-right: .5rem;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: "\f0e0";
        }

        @extend .text-primary;
    }
}

.av-bool-indicator { 
    &::before {
        display: inline-block;
        width: 1rem;
        padding-right: .5rem;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: "\f111";
    }

    &.off {
        color: rgba(0,0,0,0.4);
    }

    &.on {
        @extend .text-primary;
    }
}

