﻿/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE and Edge */
.scrollbar-hide-ie-edge {
    -ms-overflow-style: none;
}

/* Show scrollbar for Chrome, Safari and Opera */
.scrollbar-show::-webkit-scrollbar {
    display: normal;
}

/* Show scrollbar for IE and Edge */
.scrollbar-show-ie-edge {
    -ms-overflow-style: auto;
}

.custom-scrollbar {
    scrollbar-width: thin;

    ::-webkit-scrollbar {
        width: 2px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}



/* Hide scrollbar for Chrome, Safari and Opera */
.example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
    -ms-overflow-style: none !important; /* IE and Edge */
    scrollbar-width: none !important; /* Firefox */
}