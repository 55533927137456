﻿$site-colors: ( "red": #E53424, "green": #28A745, "medium-grey": #efeff0 );

$access-level-colors: ( 
    "Full Access": #5B9152, 
    "First Responders": #1E9575, 
    "Media": #E3AB3D, 
    "Private Sector": #9C391D, 
    "TMC Operator": #61350D, 
);

.algo-text-color-full-access{
    color: map-get($access-level-colors, "Full Access");
}

.algo-text-color-first-responders {
    color: map-get($access-level-colors, "First Responder");
}

.algo-text-color-media {
    color: map-get($access-level-colors, "Media");
}

.algo-text-color-private-sector {
    color: map-get($access-level-colors, "Private Sector");
}

.algo-text-color-tmc-operator {
    color: map-get($access-level-colors, "TMC Operator");
}
