﻿$grid-spacer: 2rem;

.aa-service-status-content {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 70% 1fr;
    grid-gap: $grid-spacer;

    @include media-breakpoint-down(sm) {
        margin-left: $spacer * .25;
        margin-right: $spacer * .25;
    }

    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        margin-left: $spacer * 1.5;
        margin-right: $spacer * 1.5;
    }

    @include media-breakpoint-up(lg) {
        margin-left: $spacer * 1.25;
        margin-right: $spacer * 1.25;
    }

    @include media-breakpoint-up(xl) {
        margin-left: $spacer * 1.25;
        margin-right: $spacer * 1.2;
    }

    @extend .my-2;

    .aa-header {
        @include media-breakpoint-down(sm) {
            grid-column: 1;
            grid-row: 1;
        }

        grid-row: 1;
        grid-column: span 2;
        text-align: center;
    }

    .aa-service-status-history {
        @include media-breakpoint-down(sm) {
            grid-column: 1;
            grid-row: 3;
        }

        grid-column: 1;
        grid-row: 2;

        .aa-history-item-card-body { 
            @extend .card-body;
            @extend .border-bottom;
            @extend .border-light;
        }

        .aa-history-item {
            display: grid;
            grid-template-columns: 1fr 80%;

            @include media-breakpoint-down(md) {
                grid-template-columns: 1fr;
                grid-row-gap: 1rem;

            }

            grid-column-gap: $grid-spacer;

            .aa-history-item-timestamp {
                .aa-history-item-timepart {
                    font-size: .8rem;
                    @extend .text-muted;
                }
            }

            .aa-history-item-message {
                h5 {
                    font-weight: bolder;
                }

                p {
                    line-height: 1.5rem;
                    @extend .mb-0;
                }
            }
        }
    }

    .aa-service-status-latest {
        @include media-breakpoint-down(sm) {
            grid-column: 1;
            grid-row: 2;
        }

        grid-column: 2;
        grid-row: 2;

        .aa-status-item {
            cursor: pointer;

            &:hover {
                background-color: rgba(0, 0, 0, 0.075);
            }

            @extend .list-group-item;

            .aa-status-item-content {
                display: grid;
                grid-template-rows: auto;
                grid-template-columns: 80% 1fr;

                .aa-status-indicator {
                    justify-self: end;
                    align-self: center;
                }
            }
        }
    }
}
